import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import AnimatedHeading from "../components/animated-heading"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import plants from "../images/plants.svg"
import singlePlant from "../images/single-plant.svg"

const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: minmax(300px, auto) minmax(auto, 250px) 1fr auto;
  min-height: 80vh;
  background-color: ${props => props.theme.colors.white};

  @media (min-width: 700px) {
    grid-template-rows: minmax(500px, auto) minmax(auto, 400px) 1fr auto;
    min-height: unset;
  }
`

const HeroWrapper = styled.div`
  background-image: linear-gradient(
    90deg,
    rgba(93, 27, 123, 0.69) 0%,
    rgba(113, 13, 111, 0.8) 48%,
    rgba(132, 28, 97, 0.68) 100%
  );
  width: 100%;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    background: url(${plants});
    background-repeat: no-repeat;
    background-size: 213% 152%;
    background-repeat: no-repeat;
    background-position: top left;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;

    @media (min-width: 700px) {
      background-size: 184% 152%;
    }

    @media (min-width: 1000px) {
      background-size: cover;
      background-position: center center;
    }
  }
`

const Hero = styled(Container)`
  color: ${props => props.theme.colors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  @media (min-width: 1000px) {
    max-width: 40%;
  }

  h1 {
    font-size: 8vmin;
    text-align: center;

    @media (min-width: 700px) {
      font-size: 7vmin;
    }

    @media (min-width: 1000px) {
      font-size: 6.5vmin;
    }
  }
`

const OverviewSection = styled.section`
  background-color: ${props => props.theme.colors.lightGray};
  padding: 50px 0;
  color: ${props => props.theme.colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 1000px) {
    padding: 100px 0;
  }

  .intro {
    max-width: 90%;
    margin: 0 auto;
    font-size: ${props => props.theme.typography.scale[3]};
    line-height: 1.5;
    text-align: center;

    @media (min-width: 1000px) {
      max-width: 65%;
      font-size: ${props => props.theme.typography.scale[5]};
    }
  }
`
const InterestsSection = styled.section`
  padding: 50px 0;
  position: relative;
  background: linear-gradient(to right,rgb(197 176 198 / 75%),rgb(185 111 165 / 60%),rgb(89 27 86 / 69%));
  color: ${props => props.theme.colors.black};
  overflow: hidden;

  &:after {
    content: "";
    background: url(${singlePlant});
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 60% 45%;
    position: absolute;
    bottom: 0;
    right: -20px;
    width: 100%;
    height: 100%;
    opacity: 0.4;

    @media (min-width: 700px) {
      padding: 100px 0;
      bottom: -80px;
      background-position: 70% 100%;
      background-size: 40% 100%;
    }

    @media (min-width: 1000px) {
      bottom: -100px;
      background-position: 60% 100%;
      background-size: 30% 100%;
    }
  }

  h2 {
    margin-top: 0;
  }
`

const AboutMeSection = styled.section`
  background-color: ${props => props.theme.colors.lightGray};
  padding: 50px 0;
  color: ${props => props.theme.colors.black};

  @media (min-width: 700px) {
    padding: 100px 0;
  }

  h2 {
    text-align: center;
  }

  .gatsby-image-wrapper {
    border: 6px solid ${props => props.theme.colors.lightishGray};
    border-radius: 50%;

    @media (min-width: 700px) {
      float: right;
      width: 250px;
      margin-left: ${props => props.theme.spacers.sm};
      max-width: unset;
      height: auto;
      shape-outside: circle();
    }

    @media (min-width: 700px) {
      margin-left: ${props => props.theme.spacers.md};
    }
  }
`

const TextWithImageWrapper = styled.div`
  text-align: center;
  max-width: 90%;
  margin: 0 auto;

  @media (min-width: 700px) {
    text-align: left;
    overflow: hidden;
    max-width: 80%;
    margin: 0 auto;
  }

  .blog-link {
    display: block;
  }
`

const IndexPage = ({ data }) => (
  <Layout verticalPadding={false}>
    <SEO title="Home" path="/" />
    <PageWrapper>
      <HeroWrapper>
        <Hero>
          <AnimatedHeading>
            I build engaging user experiences for the web.
          </AnimatedHeading>
        </Hero>
      </HeroWrapper>
      <OverviewSection>
        <Container>
          <div className="intro">
            <p>Hello there! I'm Sheelah.</p>
            <p>
              I use my development experience and design sensibilities to build
              pixel-perfect, performance-optimized experiences for the web.
            </p>
          </div>
        </Container>
      </OverviewSection>
      <InterestsSection>
        <Container>
          <h2>Some of My Interests</h2>
          <ul className="unstyled_list">
            <li>CSS</li>
            <li>JavaScript</li>
            <li>Web animation</li>
            <li>Design systems</li>
            <li>UI design</li>
          </ul>
        </Container>
      </InterestsSection>
      <AboutMeSection id="about">
        <Container>
          <h2>About Me</h2>
          <TextWithImageWrapper>
            <Img fixed={data.file.childImageSharp.fixed} />
            <p>
              I'm a front-end web developer specializing in front-end and UI
              development. I build fully responsive websites and web
              applications that work seamlessly on all types of devices and
              screen sizes. I also blog sometimes!
            </p>
            <span className="blog-link">
              <Link to="/blog/">Visit my blog</Link>
            </span>
          </TextWithImageWrapper>
        </Container>
      </AboutMeSection>
    </PageWrapper>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "sheelah.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 200, height: 200, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
IndexPage.propTypes = {
  data: PropTypes.object,
}

export default IndexPage
