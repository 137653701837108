import React from "react"
import PropTypes from "prop-types"
import { cx } from "emotion"
import styled from "@emotion/styled"

const Heading = styled.h1`
  @media (min-width: 700px) {
    animation: fade-in-up 0.4s ease forwards;
    opacity: 0;
  }

  @keyframes fade-in-up {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
`

const AnimatedHeading = ({ as, className, children }) => {
  const classNames = cx("heading", className)

  return (
    <Heading as={as} className={classNames}>
      {children}
    </Heading>
  )
}

AnimatedHeading.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default AnimatedHeading
